import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
//import { withFirebase, isLoaded, isEmpty } from 'react-redux-firebase';
//import firebase from 'firebase';
import Spinner from './layout/Spinner';
//import DatePicker from 'react-datepicker';
import UserTable from './tables/UserTable';
import ReactExport from 'react-export-excel';

import 'react-datepicker/dist/react-datepicker.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function dateToString(dateData) {
  let year = dateData.substring(0, 4);
  let month = dateData.substring(4, 6);
  let day = dateData.substring(6, 8);
  let hr = dateData.substring(8, 10);
  let mi = dateData.substring(10, 12);
  let sec = dateData.substring(12, 14);
  return day + '/' + month + '/' + year + ' ' + hr + ':' + mi + ':' + sec;
}

class UsersByDealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUsers: null,
      dealercode: ''
    };
    this.setState({
      dealercode: this.props.match.params.dealercode
    });
  }

  // state = {
  //   totalUsers: null,
  //   startDate: null,
  //   endDate: null
  // };

  // static getDerivedStateFromProps(props, state) {
  //   const { dealercode } = props;
  // }

  static getDerivedStateFromProps(props, state) {
    const { users } = props;

    if (users) {
      const total = users.reduce((total, user) => {
        return total + 1;
      }, 0);

      return { totalUsers: total };
    }

    return null;
  }

  goBack = e => {
    e.preventDefault();

    this.props.history.goBack();
  };

  render() {
    const { users } = this.props;
    const { totalUsers, dealercode } = this.state;
    //const BrowserHistory = require('react-router/lib/BrowserHistory').default;

    //const { dealercode } = this.props.match.params.dealercode;
    //const { dealercode } = this.props.match.params.dealercode;
    //const { dealercode } = this.props.dealercode;
    //const { usersByDealer } =

    // console.log('C1:' + this.props.match.params.dealercode);
    // console.log('C2:' + dealercode);
    // //console.log('C3:' + this.props.dealercode);
    // console.log('C4:' + this.state.dealercode);

    if (users) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <h2>
                {' '}
                <i className="fas fa-users" /> Users by dealer{' '}
              </h2>
            </div>
            <div className="col-md-6">
              <h5 className="text-right text-secondary">
                Total Users <span className="text-primary">{totalUsers}</span>
              </h5>
            </div>
          </div>

          <div className="row">
            <button className="btn btn-primary" onClick={this.goBack}>
              <i className="fas fa-arrow-circle-left" /> Back
            </button>
          </div>
          <div className="row">
            <div className="exportbutton">
              <ExcelFile
                element={
                  <button className="btn btn-success">
                    <i className="fas fa-file-export" /> Export to Excel
                  </button>
                }
                //filename={() => 'Users' + startDateStr + '_' + endDateStr} //"Users"{{$startDate}
                filename={
                  'UsersByDealer' + '_' + this.props.match.params.dealercode
                }
              >
                <ExcelSheet data={users} name="UsersData">
                  <ExcelColumn label="FirstName" value="firstname" />
                  <ExcelColumn label="LastName" value="lastname" />
                  <ExcelColumn label="Email" value="email" />
                  <ExcelColumn label="Phone" value="phone" />
                  <ExcelColumn
                    label="Register Date"
                    value={col => dateToString(col.createdAt)}
                  />
                  <ExcelColumn
                    label="Last Online"
                    value={col => dateToString(col.updatedAt)}
                  />
                  <ExcelColumn label="Dealer Thai" value="dealer_name_th" />
                  <ExcelColumn label="Dealer English" value="dealer_name_en" />
                  <ExcelColumn
                    label="Vehicle"
                    value={col => (col.vehicle[0] ? col.vehicle[0] : '')}
                  />
                  <ExcelColumn
                    label="Chassis Number"
                    value={col =>
                      col.chassisNumber[0] ? col.chassisNumber[0] : ''
                    }
                  />
                </ExcelSheet>
              </ExcelFile>
            </div>
          </div>

          <UserTable users={users} />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

UsersByDealer.propTypes = {
  firestore: PropTypes.object.isRequired,
  users: PropTypes.array,
  dealercode: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users,
    dealercode: state.dealercode
  };
};

const mapDispatchToProps = {};

export default compose(
  //firestoreConnect([{ collection: 'users' }]),
  //withFirebase,
  // firestoreConnect(props => [
  //   {
  //     collection: 'users',
  //     where: [['email', '==', 'dubdib@gmail.com']]
  //   }
  // ]),
  firestoreConnect(props => [
    {
      collection: 'users',
      where: [['friends', 'array-contains', props.match.params.dealercode]]
      //where: [['friends', 'array-contains', props.dealercode]]
      //where: [['friends', 'array-contains', '100002']]
      //orderBy: ['createdAt', 'desc'] //desc, asc
      //queryParams: ['orderByChild', '=', 'createdAt']
      //where: [['email', '==', 'dubdib@gmail.com']]
      //where: [['createdAt', '<=', '20190403000000']]
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  // connect((state, props) => ({
  //   users: state.firestore.ordered.users
  //   //dealercode: state.dealercode
  // }))
)(UsersByDealer);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import ReactExport from 'react-export-excel';
import Spinner from './layout/Spinner';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function dateToString(dateData) {
  if (dateData) {
    let year = dateData.substring(0, 4);
    let month = dateData.substring(4, 6);
    let day = dateData.substring(6, 8);
    let hr = dateData.substring(8, 10);
    let mi = dateData.substring(10, 12);
    let sec = dateData.substring(12, 14);
    return day + '/' + month + '/' + year + ' ' + hr + ':' + mi + ':' + sec;
  } else {
    return '';
  }
}

class Chats extends Component {
  // state = {
  //   totalMessages: null
  // };
  constructor(props) {
    super(props);
    this.state = {
      totalMessages: null,
      mmm: null
    };
  }

  // static getDerivedStateFromProps(props, state) {
  //   const { messages } = props;

  //   if (state.mmm) {
  //     const total = state.mmm.reduce((total, message) => {
  //       return total + 1;
  //     }, 0);

  //     return { totalMessages: total };
  //   }

  //   return null;
  // }

  goBack = e => {
    e.preventDefault();

    this.props.history.goBack();
  };

  render() {
    const { messages } = this.props;
    const { totalMessages, mmm } = this.state;
    const dummy = [
      {
        id: null,
        message: null,
        senderName: null,
        date: null,
        readDate: null
      }
    ];
    //const docId = '100002DdzMazShlOZflHqH2ZWh3AKUWLF3';
    //const mm = first(messages); //messages.first(['100002DdzMazShlOZflHqH2ZWh3AKUWLF3']);

    const chatRoomId = this.props.match.params.chatroomid;

    if (messages) {
      const first = messages.length > 0 ? messages[0] : null;
      //const mm = first['100002DdzMazShlOZflHqH2ZWh3AKUWLF3'];
      const mm = first[chatRoomId] ? first[chatRoomId] : dummy;

      //this.state.mmm = mm;

      // this.setState({
      //   mmm: mm
      // });

      // console.log('BB: ' + first.id);
      // console.log('CC: ' + chatRoomId.substring(0, 6));
      // console.log('DD: ' + this.props.match.params.chatroomid.substring(0, 6));
      // console.log('BB2: ' + first);
      // console.log('message: ' + messages);
      // console.log('mm: ' + mm);
      // console.log('chatRoomId: ' + chatRoomId);

      if (first[chatRoomId]) {
        return (
          <div>
            <div className="row">
              <div className="col-md-6">
                <h2>
                  {' '}
                  <i className="fas fa-comment" /> Chat History{' '}
                </h2>
              </div>
              <div className="col-md-6">
                {/* <h5 className="text-right text-secondary">
                  Total Messages{' '}
                  <span className="text-primary">{totalMessages}</span>
                </h5> */}
              </div>
            </div>
            <div className="backbutton">
              <button className="btn btn-primary" onClick={this.goBack}>
                <i className="fas fa-arrow-circle-left" /> Back
              </button>
            </div>

            <div className="row">
              <div className="exportbutton">
                <ExcelFile
                  element={
                    <button className="btn btn-success">
                      <i className="fas fa-file-export" /> Export to Excel
                    </button>
                  }
                  //filename={() => 'Users' + startDateStr + '_' + endDateStr} //"Users"{{$startDate}
                  filename={'Chats' + '_' + chatRoomId.substring(0, 6)}
                >
                  <ExcelSheet data={mm} name="UsersData">
                    <ExcelColumn label="Sender Name" value="senderName" />
                    <ExcelColumn label="Message" value="message" />
                    <ExcelColumn
                      label="Sent Date"
                      value={col => dateToString(col.date)}
                    />
                    <ExcelColumn
                      label="Read Date"
                      value={col => dateToString(col.readDate)}
                    />
                  </ExcelSheet>
                </ExcelFile>
              </div>
            </div>

            <table className="table table-striped">
              <thead className="thead-inverse">
                <tr>
                  <th>Sender Name</th>
                  <th>Message</th>
                  <th>Sent Date</th>
                  <th>Read Date</th>
                </tr>
              </thead>
              <tbody>
                {/* <React.Fragment> */}
                {mm.map(message => (
                  <tr key={message.id}>
                    <td>{message.senderName}</td>
                    <td>{message.message}</td>
                    <td>{dateToString(message.date)}</td>
                    <td>{dateToString(message.readDate)}</td>
                    {/* <td>${parseFloat(user.balance).toFixed(2)}</td> */}
                  </tr>
                ))}
                {/* </React.Fragment> */}
              </tbody>
            </table>
          </div>
        );
      } else {
        return (
          <div>
            <div className="row">
              <div className="col-md-6">
                <h2>
                  {' '}
                  <i className="fas fa-comment" /> Chat History{' '}
                </h2>
              </div>
              <div className="col-md-6">
                {/* <h5 className="text-right text-secondary">
                  Total Messages{' '}
                  <span className="text-primary">{totalMessages}</span>
                </h5> */}
              </div>
            </div>
            <div className="backbutton">
              <button className="btn btn-primary" onClick={this.goBack}>
                <i className="fas fa-arrow-circle-left" /> Back
              </button>
            </div>
            <div className="row">
              <table className="table table-striped">
                <thead className="thead-inverse">
                  <tr>
                    <th>Sender Name</th>
                    <th>Message</th>
                    <th>Sent Date</th>
                    <th>Read Date</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <React.Fragment> */}
                  {mm.map(message => (
                    <tr key={message.id}>
                      <td>{message.senderName}</td>
                      <td>{message.message}</td>
                      <td>{dateToString(message.date)}</td>
                      <td>{dateToString(message.readDate)}</td>
                      {/* <td>${parseFloat(user.balance).toFixed(2)}</td> */}
                    </tr>
                  ))}
                  {/* </React.Fragment> */}
                </tbody>
              </table>
            </div>
          </div>
        );
      }
    } else {
      return <Spinner />;
    }
  }
}

Chats.propTypes = {
  firestore: PropTypes.object.isRequired,
  messages: PropTypes.array
};

export default compose(
  firestoreConnect(props => [
    {
      collection: 'Message',
      doc: props.match.params.chatroomid.substring(0, 6),
      subcollections: [
        {
          collection: props.match.params.chatroomid
        }
      ]
      //doc: '100002DdzMazShlOZflHqH2ZWh3AKUWLF3'
      //where: ['id', '==', '100002']
      // child: '100002',
      // collection: '100002DdzMazShlOZflHqH2ZWh3AKUWLF3',
      // child: '39a143db-6167-4373-ba06-3e973e23d5aa'

      //where: [['email', '==', 'dubdib@gmail.com']]
      //where: [['createdAt', '<=', '20190403000000']]
    }
  ]),
  connect((state, props) => ({
    messages: state.firestore.ordered.Message
  }))
)(Chats);

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { UserIsAuthenticated, UserIsNotAuthenticated } from './helpers/auth';

import { Provider } from 'react-redux';
import store from './store';

import AppNavbar from './components/layout/AppNavbar';
import Dashboard from './components/layout/Dashboard';
import AllUsers from './components/AllUsers';
//import Users from './components/users/Users';
import Dealers from './components/Dealers';
import UsersByDealer from './components/UsersByDealer';
import ChatDealers from './components/ChatDealers';
import ChatUsersByDealer from './components/ChatUsersByDealer';
import Chats from './components/Chats';
import NotFound from './components/pages/NotFound';
import Login from './components/auth/Login';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
//import './custom.scss';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <AppNavbar />
            <div className="container">
              <Switch>
                <Route
                  exact
                  path="/"
                  component={UserIsAuthenticated(Dashboard)}
                />
                <Route
                  exact
                  path="/users"
                  component={UserIsAuthenticated(AllUsers)}
                />
                <Route
                  exact
                  path="/dealers"
                  component={UserIsAuthenticated(Dealers)}
                />
                <Route
                  exact
                  path="/usersbydealer/:dealercode"
                  component={UserIsAuthenticated(UsersByDealer)}
                />
                <Route
                  exact
                  path="/chatdealers"
                  component={UserIsAuthenticated(ChatDealers)}
                />
                <Route
                  exact
                  path="/chatusersbydealer/:dealercode"
                  component={UserIsAuthenticated(ChatUsersByDealer)}
                />
                <Route
                  exact
                  path="/chats/:chatroomid"
                  component={UserIsAuthenticated(Chats)}
                />
                <Route
                  exact
                  path="/login"
                  component={UserIsNotAuthenticated(Login)}
                />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

class AppNavbar extends Component {
  state = {
    isAuthenticated: false
  };

  static getDerivedStateFromProps(props, state) {
    const { auth } = props;

    if (auth.uid) {
      return { isAuthenticated: true };

      //if (auth.email != '999999@suzuki.co.th')
    } else {
      return { isAuthenticated: false };
    }
  }

  onLogoutClick = e => {
    e.preventDefault();

    const { firebase } = this.props;
    firebase.logout();
  };

  render() {
    const { isAuthenticated } = this.state;
    //const { auth } = this.props;

    return (
      <nav className="navbar navbar-expand-md navbar-dark                                                                                                                                                     bg-primary mb-4">
        <div className="container">
          <Link to="/" className="navbar-brand">
            Hello Suzuki
          </Link>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
          >
            <span className="navbar-toggle-icon" />
          </button> */}
          {/* <div className="collapse navbar-collapse" id="navbarMain"> */}
          <div>
            <ul className="navbar-nav mr-auto">
              {isAuthenticated ? (
                <li className="nav-item">
                  <Link to="/users" className="nav-link">
                    <i className="fas fa-id-card" /> Users
                  </Link>
                </li>
              ) : null}
              {isAuthenticated ? (
                <li className="nav-item">
                  <Link to="/dealers" className="nav-link">
                    <i className="fas fa-users" /> Users by dealer
                  </Link>
                </li>
              ) : null}
              {isAuthenticated ? (
                <li className="nav-item">
                  <Link to="/chatdealers" className="nav-link">
                    <i className="fas fa-comment" /> Chat History
                  </Link>
                </li>
              ) : null}
              {isAuthenticated ? (
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <a
                      href="#!"
                      className="nav-link"
                      onClick={this.onLogoutClick}
                    >
                      <i className="fas fa-sign-out-alt" /> Logout
                    </a>
                  </li>
                </ul>
              ) : null}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

AppNavbar.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

//export default AppNavbar;
export default compose(
  firestoreConnect(),
  connect((state, props) => ({
    auth: state.firebase.auth
  }))
)(AppNavbar);

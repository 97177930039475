import { createStore, combineReducers, compose } from 'redux';
// import firebase from 'firebase';
// import 'firebase/firestore';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { reactReduxFirebase, firebaseReducer } from 'react-redux-firebase';
import { reduxFirestore, firestoreReducer } from 'redux-firestore';
// Reducers
import notifyReducer from './reducers/notifyReducer';

//https://github.com/prescottprue/react-redux-firebase

const firebaseConfig = {
  apiKey: 'AIzaSyAEe6X1oJMxeRz2Ps3O6LyOQpe2ZNsWGZY',
  authDomain: 'chat-2d36f.firebaseapp.com',
  databaseURL: 'https://chat-2d36f.firebaseio.com',
  projectId: 'chat-2d36f',
  storageBucket: 'chat-2d36f.appspot.com',
  messagingSenderId: '821982647737'
};

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  userFirestoreForProfile: true, // Firestroe for Profile instead of Realtime DB
  enableLogging: false
};

// Init firebase instance
firebase.initializeApp(firebaseConfig);
// Init firestore
// const firestore = firebase.firestore();
// const settings = { timestampInSnapshots: true };
// firestore.settings(settings);

// Add reactReduxFirebase enhancer when making store creator
const createStoreWithFirebase = compose(
  reactReduxFirebase(firebase, rrfConfig), // firebase instance as first argument
  reduxFirestore(firebase) // <- needed if using firestore
)(createStore);

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  notify: notifyReducer
});

// Create initial state
const initialState = {};

// Create store
//const store = createStoreWithFirebase(rootReducer, initialState);
const store = createStoreWithFirebase(
  rootReducer,
  initialState,
  compose(
    reactReduxFirebase(firebase) //,
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;

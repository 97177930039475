import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
//import { withFirebase, isLoaded, isEmpty } from 'react-redux-firebase';
//import firebase from 'firebase';
import Spinner from './layout/Spinner';
//import DatePicker from 'react-datepicker';
//import UserTable from './tables/UserTable';
//import ReactExport from 'react-export-excel';

import 'react-datepicker/dist/react-datepicker.css';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

function dateToString(dateData) {
  let year = dateData.substring(0, 4);
  let month = dateData.substring(4, 6);
  let day = dateData.substring(6, 8);
  let hr = dateData.substring(8, 10);
  let mi = dateData.substring(10, 12);
  let sec = dateData.substring(12, 14);
  return day + '/' + month + '/' + year + ' ' + hr + ':' + mi + ':' + sec;
}

class ChatUsersByDealer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUsers: null //,
      //dealercode: ''
    };
    // this.setState({
    //   dealercode: this.props.match.params.dealercode
    // });
  }

  // state = {
  //   totalUsers: null,
  //   startDate: null,
  //   endDate: null
  // };

  // static getDerivedStateFromProps(props, state) {
  //   const { dealercode } = props;
  // }

  static getDerivedStateFromProps(props, state) {
    const { users } = props;

    if (users) {
      const total = users.reduce((total, user) => {
        return total + 1;
      }, 0);

      return { totalUsers: total };
    }

    return null;
  }

  goBack = e => {
    e.preventDefault();

    this.props.history.goBack();
  };

  render() {
    const { users } = this.props;
    const { totalUsers } = this.state;
    const dealercode = this.props.match.params.dealercode;
    //const { dealercode } = this.props.match.params.dealercode;
    //const { dealercode } = this.props.dealercode;
    //const { usersByDealer } =

    //console.log('DD:' + dealercode);

    // console.log('C1:' + this.props.match.params.dealercode);
    // console.log('C2:' + dealercode);
    // //console.log('C3:' + this.props.dealercode);
    // console.log('C4:' + this.state.dealercode);

    if (users) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <h2>
                {' '}
                <i className="fas fa-comment" /> Chat History{' '}
              </h2>
            </div>
            <div className="col-md-6">
              <h5 className="text-right text-secondary">
                Total Users <span className="text-primary">{totalUsers}</span>
              </h5>
            </div>
          </div>
          <div className="backbutton">
            <button className="btn btn-primary" onClick={this.goBack}>
              <i className="fas fa-arrow-circle-left" /> Back
            </button>
          </div>
          {/* <div className="row">
            <ExcelFile
              element={<button>Download Data</button>}
              filename="Users"
            >
              <ExcelSheet data={users} name="UsersData">
                <ExcelColumn label="FirstName" value="firstname" />
                <ExcelColumn label="LastName" value="lastname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Phone" value="phone" />
              </ExcelSheet>
            </ExcelFile>
          </div> */}

          <table className="table table-striped">
            <thead className="thead-inverse">
              <tr>
                <th />
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Chassis Number</th>
                <th>Register Date</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.objectId}>
                  <td>
                    {' '}
                    <Link
                      to={`/chats/${dealercode + user.objectId}`}
                      // to={`/usersbydealer/${dealer.dealer_code}`}
                      className="btn btn-secondary btn-sm"
                    >
                      <i className="fas fa-arrow-circle-right" /> Chats
                    </Link>
                  </td>
                  {/* <td>${parseFloat(user.balance).toFixed(2)}</td> */}
                  <td>{user.firstname}</td>
                  <td>{user.lastname}</td>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{user.chassisNumber == '.' ? '' : user.chassisNumber}</td>
                  <td>{dateToString(user.createdAt)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

ChatUsersByDealer.propTypes = {
  firestore: PropTypes.object.isRequired,
  users: PropTypes.array,
  dealercode: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    users: state.firestore.ordered.users,
    dealercode: state.dealercode
  };
};

const mapDispatchToProps = {};

export default compose(
  //firestoreConnect([{ collection: 'users' }]),
  //withFirebase,
  // firestoreConnect(props => [
  //   {
  //     collection: 'users',
  //     where: [['email', '==', 'dubdib@gmail.com']]
  //   }
  // ]),
  firestoreConnect(props => [
    {
      collection: 'users',
      where: [['friends', 'array-contains', props.match.params.dealercode]]
      //where: [['friends', 'array-contains', props.dealercode]]
      //where: [['friends', 'array-contains', '100002']]
      //orderBy: ['createdAt', 'desc'] //desc, asc
      //queryParams: ['orderByChild', '=', 'createdAt']
      //where: [['email', '==', 'dubdib@gmail.com']]
      //where: [['createdAt', '<=', '20190403000000']]
    }
  ]),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
  // connect((state, props) => ({
  //   users: state.firestore.ordered.users
  //   //dealercode: state.dealercode
  // }))
)(ChatUsersByDealer);

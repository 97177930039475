import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
//import { firestoreConnect } from 'react-redux-firebase';
//import { firebaseConnect } from 'react-redux-firebase';
import { withFirestore, isLoaded, isEmpty } from 'react-redux-firebase';
import Spinner from './layout/Spinner';
import DatePicker from 'react-datepicker';
import UserTable from './tables/UserTable';
import ReactExport from 'react-export-excel';
import { format } from 'date-fns';

import 'react-datepicker/dist/react-datepicker.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const dateToString = (dateData) => {

// };

function dateToString(dateData) {
  let year = dateData.substring(0, 4);
  let month = dateData.substring(4, 6);
  let day = dateData.substring(6, 8);
  let hr = dateData.substring(8, 10);
  let mi = dateData.substring(10, 12);
  let sec = dateData.substring(12, 14);
  return day + '/' + month + '/' + year + ' ' + hr + ':' + mi + ':' + sec;
}
class AllUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalUsers: null,
      startDate: new Date(), //new Date()
      endDate: new Date(),
      startDateStr: format(new Date(), 'yyyyMMdd') + '000000',
      endDateStr: format(new Date(), 'yyyyMMdd') + '235959',
      isShowLoading: false
    };
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleShowLoading = this.handleShowLoading.bind(this);
  }

  handleChangeStartDate(date) {
    // console.log('S: ' + date);
    // console.log('S2:' + format(date, 'yyyyMMdd') + '000000');
    this.setState({
      startDate: date,
      startDateStr: format(date, 'yyyyMMdd') + '000000'
    });
  }

  handleChangeEndDate(date) {
    //console.log('E: ' + date);
    this.setState({
      endDate: date,
      endDateStr: format(date, 'yyyyMMdd') + '235959'
    });
  }

  handleShowLoading(isShow) {
    this.setState({
      isShowLoading: isShow
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { users } = props;

    if (users) {
      const total = users.reduce((total, user) => {
        return total + 1;
      }, 0);

      return { totalUsers: total, isShowLoading: false };
    }

    return null;
  }

  render() {
    const { firestore, users } = this.props;
    //const { totalUsers, isShowLoading } = this.state;
    // const { startDate } = this.state.startDate;
    // const { endDate } = this.state.endDate;
    // const { startDateStr } = this.state.startDateStr;
    // const { endDateStr } = this.state.endDateStr;

    const {
      totalUsers,
      //startDate,
      //endDate,
      startDateStr,
      endDateStr,
      isShowLoading
    } = this.state;

    //const isShow = isShowLoading;

    // const ss = '20190411000000';

    // console.log('a1: ' + startDateStr);
    // console.log('a2: ' + ss);

    //var isShowLoad = isShowLoading;

    // if (isLoaded(users)) {
    //   isShowLoading = false;
    // }
    // if (!isEmpty(users)) {
    //   this.handleShowLoading(false);
    //   //isShowLoading = false;
    // }

    //console.log('CC:' + totalUsers + 'E:' + isShowLoading);

    // if (users) {
    //   this.handleShowLoading(false);
    // }

    var showLoading = isShowLoading ? <Spinner /> : null;

    //var empty = !isEmpty(users) ? <Spinner /> : null;

    const usersList = !isLoaded(users) ? (
      //{ showLoading }
      //<Spinner />
      ''
    ) : isEmpty(users) ? (
      <h4>
        <span className="text-danger">User list is empty</span>
      </h4>
    ) : (
      //{this.handleShowLoading(false)}
      //: Object.keys(users).map((key, objectId) => <td>{users.firstname}</td>);
      <div>
        <div className="row">
          <div className="exportbutton">
            <ExcelFile
              element={
                <button className="btn btn-success">
                  <i className="fas fa-file-export" /> Export to Excel
                </button>
              }
              //filename={() => 'Users' + startDateStr + '_' + endDateStr} //"Users"{{$startDate}
              filename={
                'Users' +
                '_' +
                startDateStr.substring(0, 8) +
                '_' +
                endDateStr.substring(0, 8)
              }
            >
              <ExcelSheet data={users} name="UsersData">
                <ExcelColumn label="FirstName" value="firstname" />
                <ExcelColumn label="LastName" value="lastname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Phone" value="phone" />
                <ExcelColumn
                  label="Register Date"
                  value={col => dateToString(col.createdAt)}
                />
                <ExcelColumn
                  label="Last Online"
                  value={col => dateToString(col.updatedAt)}
                />
                <ExcelColumn label="Dealer Thai" value="dealer_name_th" />
                <ExcelColumn label="Dealer English" value="dealer_name_en" />
                <ExcelColumn
                  label="Vehicle"
                  value={col => (col.vehicle[0] ? col.vehicle[0] : '')}
                />
                <ExcelColumn
                  label="Chassis Number"
                  value={col =>
                    col.chassisNumber[0] ? col.chassisNumber[0] : ''
                  }
                />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </div>
        <div className="row">
          <UserTable users={users} />
        </div>
      </div>
    );

    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2>
              {' '}
              <i className="fas fa-id-card" /> Users{' '}
            </h2>
          </div>
          <div className="col-md-6">
            <h5 className="text-right text-secondary">
              Total Users <span className="text-primary">{totalUsers}</span>
            </h5>
          </div>
        </div>
        <div className="box">
          <div className="col-md-4">
            <span className="text-primary">Start Date: </span>
            <DatePicker
              selected={this.state.startDate}
              onSelect={this.handleSelect}
              onChange={this.handleChangeStartDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          {/* <div className="col-md-3">Selected Start Date: {startDate}</div> */}
          <div className="col-md-4">
            <span className="text-primary">End Date: </span>
            <DatePicker
              selected={this.state.endDate}
              onSelect={this.handleSelect}
              onChange={this.handleChangeEndDate}
              dateFormat="dd/MM/yyyy"
            />
          </div>
          {/* <div className="col-md-3">Selected End Date: {endDate}</div> */}
          {/* <button onClick={() => firestore.get('users')}>Get Data</button> */}
          <div className="col-md-2">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                //isShowLoading = true;
                this.handleShowLoading(true);
                firestore.get({
                  collection: 'users',
                  where: [
                    ['createdAt', '>=', startDateStr],
                    ['createdAt', '<=', endDateStr]
                  ],
                  orderBy: ['createdAt', 'asc']
                });
              }}
            >
              <i className="fas fa-table" /> Get Users
            </button>
          </div>
        </div>
        {showLoading}
        {/* {empty} */}
        {usersList}
        {/* if (users.lenght > 0) {<UserTable users={users} />} */}
      </div>
    );
  }
}

AllUsers.propTypes = {
  firestore: PropTypes.object.isRequired,
  users: PropTypes.array
};

export default compose(
  //withFirebase, // or firebaseConnect()
  // firebaseConnect(),
  // connect(state => ({
  //   users: state.firebase.data.users
  //   // profile: state.firebase.profile // load profile
  // }))
  // firestoreConnect(props => [
  //   {
  //     collection: 'users',
  //     where: [
  //       ['createdAt', '>=', '20190401000000'],
  //       ['createdAt', '<=', '20190403000000']
  //     ],
  //     orderBy: ['createdAt', 'asc']
  //     //where: [['email', '==', 'dubdib@gmail.com']]
  //     //where: [['createdAt', '<=', '20190403000000']]
  //   }
  // ]),
  withFirestore,
  connect((state, props) => ({
    users: state.firestore.ordered.users
  }))
)(AllUsers);

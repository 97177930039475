import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Spinner from './layout/Spinner';

class ChatDealers extends Component {
  state = {
    totalDealers: null
  };

  static getDerivedStateFromProps(props, state) {
    const { dealers } = props;

    if (dealers) {
      const total = dealers.reduce((total, dealer) => {
        return total + 1;
      }, 0);

      return { totalDealers: total };
    }

    return null;
  }

  render() {
    const { dealers } = this.props;
    const { totalDealers } = this.state;

    if (dealers) {
      return (
        <div>
          <div className="row">
            <div className="col-md-6">
              <h2>
                {' '}
                <i className="fas fa-comment" /> Chat History{' '}
              </h2>
            </div>
            <div className="col-md-6">
              <h5 className="text-right text-secondary">
                Total Dealers{' '}
                <span className="text-primary">{totalDealers}</span>
              </h5>
            </div>
          </div>

          <table className="table table-striped">
            <thead className="thead-inverse">
              <tr>
                <th />
                <th>Dealer Code</th>
                <th>Dealer Thai Name</th>
                <th>Dealer English Name</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {dealers.map(dealer => (
                <tr key={dealer.dealer_code}>
                  <td>
                    {' '}
                    <Link
                      to={`/chatusersbydealer/${dealer.dealer_code}`}
                      // to={`/usersbydealer/${dealer.dealer_code}`}
                      className="btn btn-secondary btn-sm"
                    >
                      <i className="fas fa-arrow-circle-right" /> Friends
                    </Link>
                  </td>
                  <td>{dealer.dealer_code}</td>
                  <td>{dealer.dealer_name_th}</td>
                  <td>{dealer.dealer_name_en}</td>
                  <td>{dealer.phone_call}</td>
                  {/* <td>${parseFloat(user.balance).toFixed(2)}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

ChatDealers.propTypes = {
  firestore: PropTypes.object.isRequired,
  dealers: PropTypes.array
};

export default compose(
  firestoreConnect(props => [
    {
      collection: 'dealers',
      where: [['dealer_code', '<', '999999']]

      //where: [['email', '==', 'dubdib@gmail.com']]
      //where: [['createdAt', '<=', '20190403000000']]
    }
  ]),
  connect((state, props) => ({
    dealers: state.firestore.ordered.dealers
  }))
)(ChatDealers);

import React from 'react';
//import Users from '../users/Users';
//import Users from '../Users';
//import Users2 from '../Users2';
import AllUsers from '../AllUsers';
//import Dealers from '../Dealers';
//import UsersByDealer from '../UsersByDealer';
import Sidebar from '../layout/Sidebar';

export default () => {
  return (
    <div className="row">
      {/* 10 columns div */}
      <div className="col-md-12">
        {/* <Users /> */}
        {/* <Dealers /> */}
        {/* <UsersByDealer dealercode="100002" /> */}
        {/* <Users3 /> */}
        <AllUsers />
      </div>
      {/* <div className="col-md-2">
        <Sidebar />
      </div> */}
    </div>
  );
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';

//20190411085306;
function dateToString(dateData) {
  let year = dateData.substring(0, 4);
  let month = dateData.substring(4, 6);
  let day = dateData.substring(6, 8);
  let hr = dateData.substring(8, 10);
  let mi = dateData.substring(10, 12);
  let sec = dateData.substring(12, 14);
  return day + '/' + month + '/' + year + ' ' + hr + ':' + mi + ':' + sec;
}

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: this.props
    };
  }

  render() {
    const { users } = this.props;

    return (
      <table className="table table-striped">
        <thead className="thead-inverse">
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Chassis Number</th>
            <th>Register Date</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.objectId}>
              <td>{user.firstname}</td>
              <td>{user.lastname}</td>
              <td>{user.email}</td>
              <td>{user.phone}</td>
              <td>{user.chassisNumber == '.' ? '' : user.chassisNumber}</td>
              {/* <td>${parseFloat(user.balance).toFixed(2)}</td> */}
              <td>{dateToString(user.createdAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

UserTable.propTypes = {
  users: PropTypes.array.isRequired
};

export default UserTable;
